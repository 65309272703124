import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-row" }
const _hoisted_2 = {
  key: 0,
  class: "separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePromptElement = _resolveComponent("BasePromptElement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Prompt Elements", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("ol", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: element.name
        }, [
          (_ctx.separate(element))
            ? (_openBlock(), _createElementBlock("li", _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createVNode(_component_BasePromptElement, {
              label: element.name,
              tooltip: element.description,
              onClick: ($event: any) => (_ctx.pushToPrompt(index))
            }, null, 8, ["label", "tooltip", "onClick"])
          ])
        ], 64))
      }), 128))
    ])
  ], 64))
}