import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CubeTransparentIcon = _resolveComponent("CubeTransparentIcon")!

  return (_ctx.empty)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["empty-state", { dark: _ctx.darkMode }])
      }, [
        _createVNode(_component_CubeTransparentIcon, { class: "empty-state-icon" }),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2))
    : _createCommentVNode("", true)
}