import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hint"
}
const _hoisted_2 = { class: "list-row" }
const _hoisted_3 = {
  for: "import-ps1",
  class: "import-prompt"
}
const _hoisted_4 = {
  for: "import-prompt_command",
  class: "import-prompt"
}
const _hoisted_5 = {
  key: 0,
  class: "import-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_PromptElement = _resolveComponent("PromptElement")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Your Prompt", -1)),
    _createVNode(_component_IconButton, {
      style: _normalizeStyle({ visibility: _ctx.empty ? 'hidden' : undefined }),
      title: "Remove all elements",
      icon: "TrashIcon",
      onClick: _ctx.clear
    }, null, 8, ["style", "onClick"]),
    _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
    (!_ctx.empty)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Drag elements to reorder them or click the × icon to remove them."))
      : _createCommentVNode("", true),
    _createElementVNode("ol", _hoisted_2, [
      _createVNode(_component_draggable, {
        modelValue: _ctx.elements,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.elements) = $event)),
        "item-key": "id",
        onEnd: _ctx.storeState
      }, {
        item: _withCtx(({ element }) => [
          _createElementVNode("li", null, [
            _createVNode(_component_PromptElement, {
              id: element.id
            }, null, 8, ["id"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onEnd"])
    ]),
    _createVNode(_component_EmptyState, { empty: _ctx.empty }, {
      default: _withCtx(() => [
        _cache[11] || (_cache[11] = _createElementVNode("p", null, "You have not added any elements to your prompt yet.", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, "Click on a prompt element to start.", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
        _cache[14] || (_cache[14] = _createElementVNode("p", null, [
          _createTextVNode("Alternatively, you can paste your existing "),
          _createElementVNode("code", null, "PS1"),
          _createTextVNode(" string to import it:")
        ], -1)),
        _createElementVNode("label", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("code", null, "$PS1", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            id: "import-ps1",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ps1input) = $event)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.promptInputError = '')),
            placeholder: "echo $PS1",
            spellcheck: "false"
          }, null, 544), [
            [_vModelText, _ctx.ps1input]
          ])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("label", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("code", null, "$PROMPT_COMMAND", -1)),
          _cache[8] || (_cache[8] = _createTextVNode(" ")),
          _cache[9] || (_cache[9] = _createElementVNode("small", null, "(optional)", -1)),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            id: "import-prompt_command",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.promptCommandInput) = $event)),
            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.promptInputError = '')),
            placeholder: "echo $PROMPT_COMMAND",
            spellcheck: "false"
          }, null, 544), [
            [_vModelText, _ctx.promptCommandInput]
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_IconButton, {
          icon: "ArrowDownOnSquareIcon",
          title: "Import prompt",
          onClick: _ctx.importPS1
        }, null, 8, ["onClick"]),
        (_ctx.promptInputError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.promptInputError), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["empty"])
  ], 64))
}