import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hint"
}
const _hoisted_2 = {
  key: 1,
  class: "hint"
}
const _hoisted_3 = {
  key: 2,
  class: "hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Output", -1)),
    _createVNode(_component_IconButton, {
      icon: "ClipboardIcon",
      title: "Copy command to clipboard",
      onClick: _ctx.copyToClipboard
    }, null, 8, ["onClick"]),
    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "hint" }, [
      _createTextVNode(" Paste this line into your shell to test your prompt or append it to your "),
      _createElementVNode("code", null, "~/.bashrc"),
      _createTextVNode(" file to set it permanently. ")
    ], -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["ps1", { dark: _ctx.darkMode }])
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.ps1), 1)
    ], 2),
    (_ctx.hasElement('Set Window Title'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createTextVNode(" Elements between the "),
          _createElementVNode("code", null, "Set Window Title", -1),
          _createTextVNode(" and the next "),
          _createElementVNode("code", null, "Bell", -1),
          _createTextVNode(" element are used to modify the window title of the terminal, if supported. Any styling properties of those elements are ignored. ")
        ])))
      : _createCommentVNode("", true),
    (_ctx.hasElement('Advanced Git Prompt'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createStaticVNode(" The <code data-v-0ebe29b0>Advanced Git Prompt</code> element requires some extra work: Copy the <a href=\"https://github.com/git/git/blob/master/contrib/completion/git-prompt.sh\" target=\"_blank\" rel=\"noopener noreferrer\" data-v-0ebe29b0><code data-v-0ebe29b0>git-prompt.sh</code></a> file to your home directory and <code data-v-0ebe29b0>source</code> it in your <code data-v-0ebe29b0>~/.bashrc</code> file (as described in <code data-v-0ebe29b0>git-prompt.sh</code>). By default the status will only contain the current branch name, however you can include more information using global or repository-local configuration options (which are also described in <code data-v-0ebe29b0>git-prompt.sh</code>). ", 13)
        ])))
      : _createCommentVNode("", true),
    (_ctx.hasElement('Working Directory') || _ctx.hasElement('Working Directory (Basename)'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
          _createTextVNode(" You can limit the number of components in the working directory path by setting the "),
          _createElementVNode("a", {
            href: "https://www.gnu.org/software/bash/manual/html_node/Bash-Variables.html#index-PROMPT_005fDIRTRIM",
            target: "_blank",
            rel: "noopener noreferrer"
          }, [
            _createElementVNode("code", null, "PROMPT_DIRTRIM")
          ], -1),
          _createTextVNode(" environment variable in your "),
          _createElementVNode("code", null, "~/.bashrc", -1),
          _createTextVNode(" file. ")
        ])))
      : _createCommentVNode("", true)
  ], 64))
}