import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "parameter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteElementIcon = _resolveComponent("DeleteElementIcon")!
  const _component_BasePromptElement = _resolveComponent("BasePromptElement")!

  return (_ctx.data !== undefined)
    ? (_openBlock(), _createBlock(_component_BasePromptElement, {
        key: 0,
        label: _ctx.data.type.name,
        class: _normalizeClass({ selected: _ctx.selected }),
        onClick: _ctx.selectPromptElement
      }, {
        default: _withCtx(() => [
          (_ctx.data.parameters)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.parameterString), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_DeleteElementIcon, {
            onClick: _withModifiers(_ctx.deletePromptElement, ["stop"])
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["label", "class", "onClick"]))
    : _createCommentVNode("", true)
}