import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-wrapper" }
const _hoisted_2 = { id: "element-selection" }
const _hoisted_3 = { id: "prompt-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_ElementSelection = _resolveComponent("ElementSelection")!
  const _component_PromptElements = _resolveComponent("PromptElements")!
  const _component_ElementProperties = _resolveComponent("ElementProperties")!
  const _component_PS1Variable = _resolveComponent("PS1Variable")!
  const _component_PromptPreview = _resolveComponent("PromptPreview")!
  const _component_StarIcon = _resolveComponent("StarIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", {
      class: _normalizeClass({ dark: _ctx.darkMode })
    }, [
      _createVNode(_component_SiteHeader),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElementSelection)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_PromptElements)
        ]),
        _createElementVNode("div", {
          id: "properties",
          style: _normalizeStyle({ visibility: _ctx.visibleState })
        }, [
          _createVNode(_component_ElementProperties)
        ], 4),
        _createElementVNode("div", {
          id: "output",
          style: _normalizeStyle({ display: _ctx.visibleState === 'hidden' ? 'none' : 'block' })
        }, [
          _createVNode(_component_PS1Variable),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_PromptPreview)
        ], 4)
      ])
    ], 2),
    _createElementVNode("footer", null, [
      _cache[1] || (_cache[1] = _createTextVNode(" If you like this project, please consider leaving a ")),
      _createVNode(_component_StarIcon, { class: "icon" }),
      _cache[2] || (_cache[2] = _createTextVNode(" on ")),
      _cache[3] || (_cache[3] = _createElementVNode("a", {
        href: "https://github.com/Scriptim/bash-prompt-generator",
        target: "_blank",
        rel: "noopener noreferrer"
      }, "GitHub", -1)),
      _cache[4] || (_cache[4] = _createTextVNode(". "))
    ])
  ], 64))
}