import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "preview-head" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "preview-window-controls" }
const _hoisted_4 = { class: "preview-body" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_MinusCircleIcon = _resolveComponent("MinusCircleIcon")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Example Preview", -1)),
    _createVNode(_component_IconButton, {
      icon: "LightBulbIcon",
      title: "Toggle dark/light preview",
      onClick: _ctx.togglePreview
    }, null, 8, ["onClick"]),
    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["preview", { light: _ctx.light, dark: !_ctx.light }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.elements.windowTitleSet)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Terminal"))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements.windowTitle, (element) => {
          return (_openBlock(), _createElementBlock("span", {
            key: element.id
          }, _toDisplayString(element.data.type.preview(element.data.parameters)), 1))
        }), 128)),
        _cache[0] || (_cache[0] = _createElementVNode("span", null, " ", -1)),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_MinusCircleIcon, { class: "icon" }),
          _createVNode(_component_XCircleIcon, { class: "icon" })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements.prompt, (element) => {
          return (_openBlock(), _createElementBlock("span", {
            key: element.id,
            style: _normalizeStyle({
          backgroundColor: `${element.data.attributes.reverse
            ? element.data.foregroundColor?.hex ?? (_ctx.light ? '#212121' : '#fafafa')
            : element.data.backgroundColor?.hex ?? (_ctx.light ? '#fafafa' : '#212121')} !important`,
        })
          }, [
            (element.data.type.preview(element.data.parameters) !== '\n')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  style: _normalizeStyle({
            color: `${element.data.attributes.reverse
              ? element.data.backgroundColor?.hex ?? (_ctx.light ? '#fafafa' : '#212121')
              : element.data.foregroundColor?.hex ?? (_ctx.light ? '#212121' : '#fafafa')} !important`,
          }),
                  class: _normalizeClass({
            'preview-bold': element.data.attributes.bold,
            'preview-dim': element.data.attributes.dim,
            'preview-italic': element.data.attributes.italic,
            'preview-underline': element.data.attributes.underline,
            'preview-blink': element.data.attributes.blink,
            'preview-overline': element.data.attributes.overline,
          })
                }, _toDisplayString(element.data.type.preview(element.data.parameters)), 7))
              : (_openBlock(), _createElementBlock("br", _hoisted_5))
          ], 4))
        }), 128)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "cursor" }, "█", -1))
      ])
    ], 2)
  ], 64))
}