import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    title: _ctx.title,
    class: "icon-button"
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "icon-button-icon" }))
  ], 8, _hoisted_1))
}