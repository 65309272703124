import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "color-picker_reset" }
const _hoisted_2 = { class: "color-picker_color4" }
const _hoisted_3 = ["title", "onClick", "onKeydown"]
const _hoisted_4 = { class: "color-picker_color8" }
const _hoisted_5 = ["title", "onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["color-picker", { dark: _ctx.darkMode }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "color-picker_color",
        title: "Reset color",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectColor(null))),
        onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectColor(null)))
      }, [
        _createVNode(_component_XCircleIcon)
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors4, (color) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["color-picker_color", { selected: _ctx.selectedColor?.id === color.id }]),
          key: color.id,
          style: _normalizeStyle({ backgroundColor: `${color.hex} !important` }),
          title: color.name,
          onClick: ($event: any) => (_ctx.selectColor(color)),
          onKeydown: ($event: any) => (_ctx.selectColor(color))
        }, null, 46, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors8, (color) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["color-picker_color", { selected: _ctx.selectedColor?.id === color.id }]),
          key: color.id,
          style: _normalizeStyle({ backgroundColor: `${color.hex} !important` }),
          title: color.name,
          onClick: ($event: any) => (_ctx.selectColor(color)),
          onKeydown: ($event: any) => (_ctx.selectColor(color))
        }, null, 46, _hoisted_5))
      }), 128))
    ])
  ], 2))
}