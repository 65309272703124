import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "properties_parameter"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "name", "onUpdate:modelValue"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id", "name", "value", "onUpdate:modelValue"]
const _hoisted_8 = ["id", "onUpdate:modelValue", "disabled"]
const _hoisted_9 = ["id", "onUpdate:modelValue"]
const _hoisted_10 = {
  key: 1,
  class: "properties_color"
}
const _hoisted_11 = { for: "color-picker-btn-fg" }
const _hoisted_12 = { for: "color-picker-btn-bg" }
const _hoisted_13 = {
  key: 2,
  class: "properties_attributes"
}
const _hoisted_14 = { for: "attribute-bold" }
const _hoisted_15 = { for: "attribute-dim" }
const _hoisted_16 = { for: "attribute-italic" }
const _hoisted_17 = { for: "attribute-underline" }
const _hoisted_18 = { for: "attribute-blink" }
const _hoisted_19 = { for: "attribute-reverse" }
const _hoisted_20 = { for: "attribute-overline" }
const _hoisted_21 = {
  key: 3,
  class: "properties_actions"
}
const _hoisted_22 = {
  key: 4,
  class: "hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[36] || (_cache[36] = _createElementVNode("h2", null, "Properties", -1)),
    _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
    (_ctx.selected !== null && _ctx.element !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["properties-wrapper", { dark: _ctx.darkMode }])
        }, [
          (_ctx.element.type.parameters.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Parameters", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.element.type.parameters, (parameter) => {
                  return (_openBlock(), _createElementBlock("label", {
                    key: parameter.id,
                    for: `parameter_${parameter.id}`
                  }, [
                    ('parameters' in parameter)
                      ? (_openBlock(), _createElementBlock("fieldset", {
                          key: 0,
                          id: `parameter_${parameter.id}`
                        }, [
                          _createElementVNode("legend", null, _toDisplayString(parameter.label), 1),
                          (parameter.unselectLabel !== undefined)
                            ? (_openBlock(), _createElementBlock("label", {
                                key: 0,
                                for: `parameter_none_${parameter.id}`
                              }, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "radio",
                                  id: `parameter_none_${parameter.id}`,
                                  name: parameter.id,
                                  value: "",
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.element.parameters[parameter.id]) = $event)
                                }, null, 8, _hoisted_5), [
                                  [_vModelRadio, _ctx.element.parameters[parameter.id]]
                                ]),
                                _createTextVNode(" " + _toDisplayString(parameter.unselectLabel), 1),
                                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                              ], 8, _hoisted_4))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parameter.parameters, (subparameter) => {
                            return (_openBlock(), _createElementBlock("label", {
                              key: subparameter.id,
                              for: `parameter_${subparameter.id}`
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                type: "radio",
                                id: `parameter_${subparameter.id}`,
                                name: parameter.id,
                                value: subparameter.id,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.element.parameters[parameter.id]) = $event)
                              }, null, 8, _hoisted_7), [
                                [_vModelRadio, _ctx.element.parameters[parameter.id]]
                              ]),
                              _createTextVNode(" " + _toDisplayString(subparameter.label), 1),
                              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                autocomplete: "off",
                                id: `parameter_value_${subparameter.id}`,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.element.parameters[subparameter.id]) = $event),
                                disabled: _ctx.element.parameters[parameter.id] !== subparameter.id
                              }, null, 8, _hoisted_8), [
                                [_vModelText, _ctx.element.parameters[subparameter.id]]
                              ])
                            ], 8, _hoisted_6))
                          }), 128))
                        ], 8, _hoisted_3))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(parameter.label), 1),
                          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            autocomplete: "off",
                            id: `parameter_${parameter.id}`,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.element.parameters[parameter.id]) = $event)
                          }, null, 8, _hoisted_9), [
                            [_vModelText, _ctx.element.parameters[parameter.id]]
                          ])
                        ], 64))
                  ], 8, _hoisted_2))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.element.type.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Colors", -1)),
                _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_Popper, {
                  placement: "left-start",
                  arrow: ""
                }, {
                  content: _withCtx(() => [
                    _createVNode(_component_ColorPicker, {
                      selectedColor: _ctx.element.foregroundColor ?? undefined,
                      onSelectColor: _ctx.selectFg
                    }, null, 8, ["selectedColor", "onSelectColor"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_11, [
                      _createElementVNode("input", {
                        type: "button",
                        id: "color-picker-btn-fg",
                        class: _normalizeClass(["color-picker-btn", { selected: _ctx.element.foregroundColor !== null }]),
                        style: _normalizeStyle({
              backgroundColor: _ctx.element.foregroundColor ? `${_ctx.element.foregroundColor!.hex} !important` : undefined
            })
                      }, null, 6),
                      _cache[12] || (_cache[12] = _createTextVNode(" Foreground color "))
                    ])
                  ]),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_Popper, {
                  placement: "left-start",
                  arrow: ""
                }, {
                  content: _withCtx(() => [
                    _createVNode(_component_ColorPicker, {
                      selectedColor: _ctx.element.backgroundColor ?? undefined,
                      onSelectColor: _ctx.selectBg
                    }, null, 8, ["selectedColor", "onSelectColor"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_12, [
                      _createElementVNode("input", {
                        type: "button",
                        id: "color-picker-btn-bg",
                        class: _normalizeClass(["color-picker-btn", { selected: _ctx.element.backgroundColor !== null }]),
                        style: _normalizeStyle({
              backgroundColor: _ctx.element.backgroundColor ? `${_ctx.element.backgroundColor!.hex} !important` : undefined
            })
                      }, null, 6),
                      _cache[13] || (_cache[13] = _createTextVNode(" Background color "))
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.element.type.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _cache[24] || (_cache[24] = _createElementVNode("h3", null, "Display Attributes", -1)),
                _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_14, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-bold",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.element.attributes.bold) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.bold]
                  ]),
                  _cache[17] || (_cache[17] = _createElementVNode("span", null, "Bold", -1))
                ]),
                _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-dim",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.element.attributes.dim) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.dim]
                  ]),
                  _cache[18] || (_cache[18] = _createElementVNode("span", null, "Dim", -1))
                ]),
                _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-italic",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.element.attributes.italic) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.italic]
                  ]),
                  _cache[19] || (_cache[19] = _createElementVNode("span", null, "Italic", -1))
                ]),
                _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_17, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-underline",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.element.attributes.underline) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.underline]
                  ]),
                  _cache[20] || (_cache[20] = _createElementVNode("span", null, "Underline", -1))
                ]),
                _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_18, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-blink",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.element.attributes.blink) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.blink]
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("span", null, "Blink", -1))
                ]),
                _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_19, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-reverse",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.element.attributes.reverse) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.reverse]
                  ]),
                  _cache[22] || (_cache[22] = _createElementVNode("span", null, "Reverse", -1))
                ]),
                _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("label", _hoisted_20, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: "attribute-overline",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.element.attributes.overline) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.element.attributes.overline]
                  ]),
                  _cache[23] || (_cache[23] = _createElementVNode("span", null, "Overline", -1))
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.element.type.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _cache[32] || (_cache[32] = _createElementVNode("h3", null, "Actions", -1)),
                _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_IconButton, {
                  icon: "Square2StackIcon",
                  title: "Duplicate element",
                  onClick: _ctx.duplicate
                }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.element.type.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[34] || (_cache[34] = [
                _createElementVNode("p", null, "This element does not have any properties.", -1)
              ])))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_EmptyState, { empty: _ctx.empty }, {
      default: _withCtx(() => _cache[35] || (_cache[35] = [
        _createElementVNode("p", null, "You have not selected an element.", -1),
        _createElementVNode("p", null, "Click on an element in your prompt to change its properties.", -1)
      ])),
      _: 1
    }, 8, ["empty"])
  ], 64))
}